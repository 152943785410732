import React, { FunctionComponent, useEffect, useRef, useState } from "react";
import { event41 } from "../../analytics/event41";
import { event42 } from "../../analytics/event42";
import { event52 } from "../../analytics/event52";
import { event53 } from "../../analytics/event53";
import { event54 } from "../../analytics/event54";
import { ImageInterface } from "../../types/SanityTypes";
import SanityImage from "gatsby-plugin-sanity-image";
import initYouTube from "../../utils/initYouTube";
import Play from "../Play";

import "./styles.scss";
import { IMAGE_SIZES } from "../../constants";

const PROGRESS_STEP = 25;
//width on video component before we start scrolling (in pixels)
const startComponentWidth = 1095;

type YoutubeVideoBlockProps = {
  _rawCoverImage: ImageInterface;
  withoutAnimation?: boolean;
  video: string;
  videoTitle?: string;
  autoplay?: boolean;
};

const parseYouTube = (video: string) => {
  const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  const match = video.match(regExp);
  return video.startsWith("http") ? (match && match[7].length == 11 ? match[7] : "") : video;
};

const YoutubeVideoBlock: FunctionComponent<YoutubeVideoBlockProps> = props => {
  const { _rawCoverImage, video, videoTitle, withoutAnimation, autoplay = false } = props;

  const coverImage = _rawCoverImage;

  const [videoData, setVideoData] = useState<any>({});
  const [showPreview, setShowPreview] = useState(true);
  const [progress, setProgress] = useState<number>(0);
  const [videoId, setVideoId] = useState<string>("");
  const [videoContainerId, setVideoContainerId] = useState<string>("");

  //+checking if the component scrolled to viewport (for animation)
  const animatedElement = useRef<HTMLDivElement>(null);
  const [isComponentVisible, setIsComponentVisible] = useState(false);
  const [scaleValue, setScaleValue] = useState<number>(1);

  const checkVisibility = () => {
    if (animatedElement.current) {
      const positionFromBottom = animatedElement.current.getBoundingClientRect().bottom;
      const positionFromTop = animatedElement.current.getBoundingClientRect().top;
      if ((positionFromBottom + positionFromTop) * 0.5 - window.innerHeight <= 0) {
        setIsComponentVisible(true);
      }

      //zoom/scale of the whole component
      const screenWidth = window.innerWidth;
      if (startComponentWidth < screenWidth) {
        const screenHeight = window.innerHeight;
        const startScaleValue = +(startComponentWidth / screenWidth).toFixed(4);

        if (positionFromTop > window.innerHeight) {
          //component is above viewport, scale to small
          setScaleValue(startScaleValue);
        } else if (positionFromTop < 0) {
          setScaleValue(1);
        } else {
          const componentHeight = (positionFromTop + positionFromBottom) / 2;
          const visibleFraction = (screenHeight - positionFromTop) / componentHeight;
          const scaleValue = +(startScaleValue + visibleFraction * (1 - startScaleValue)).toFixed(4);
          setScaleValue(scaleValue > 1 ? 1 : scaleValue);
        }
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", checkVisibility);
    window.addEventListener("resize", checkVisibility);
    return () => {
      window.removeEventListener("scroll", checkVisibility);
      window.removeEventListener("resize", checkVisibility);
    };
  }, []);
  //-

  useEffect(() => {
    const id = parseYouTube(video);
    setVideoId(id);
    setVideoContainerId(`player-${id}-${Math.random().toString().replace(".", "")}`);
  }, [video]);

  const coverageImage = () => {
    return (
      <div className={`video-player__cover-image ${withoutAnimation ? "withoutOpacity" : ""}`}>
        <SanityImage
          {...coverImage}
          width={1440}
          htmlWidth={1440}
          className={`img-fluid ${withoutAnimation ? "" : isComponentVisible ? "zoomed" : ""}`}
          sizes={IMAGE_SIZES.FULL_WIDTH}
        />
      </div>
    );
  };

  const onProgressChange = (progress: number, title: string) => {
    switch (progress) {
      case 25:
        event52(title, videoId);
        break;
      case 50:
        event53(title, videoId);
        break;
      case 75:
        event54(title, videoId);
    }
  };

  let tickerID: any = false;
  const ticker = (playerInstance: any) => {
    tickerID = setInterval(() => {
      const p = (playerInstance.getCurrentTime() / playerInstance.getDuration()) * 100;
      setProgress(Math.floor(p / PROGRESS_STEP) * PROGRESS_STEP);
    }, 1000);
  };

  useEffect(() => {
    initYouTube();
    return clearInterval(tickerID);
  }, []);

  useEffect(() => {
    onProgressChange(progress, videoData.title);
  }, [progress]);

  const onPlayerStateChange = (e: { data: number; target: any }) => {
    if (e.data !== 1) {
      clearInterval(tickerID);
      tickerID = false;
    } else if (e.data === 1) {
      ticker(e.target);
    }
    if (e.data === 0) {
      event42(e.target.videoTitle, videoId);
    }
  };

  const handlePlayButtonClick = () => {
    window.YT.ready(() => {
      new window.YT.Player(videoContainerId, {
        videoId: videoId,
        events: {
          onReady: onPlayerReady,
          onStateChange: onPlayerStateChange
        }
      });
    });
  };

  useEffect(() => {
    if (autoplay) {
      handlePlayButtonClick();
    }
  }, []);

  const onPlayerReady = (e: {
    target: {
      playVideo: () => void;
      getVideoData: any;
      videoTitle: string;
    };
  }) => {
    e.target.playVideo();
    setVideoData(e.target.getVideoData());
    event41(e.target.videoTitle, videoId);
    setShowPreview(false);
  };

  return (
    <div
      className="video-player spb-md-64 spb-xs-36 spt-md-64 spt-xs-36"
      ref={animatedElement}
      style={withoutAnimation ? { paddingBottom: 0, paddingTop: 0 } : undefined}
    >
      <div
        className="video-player__wrapper"
        style={withoutAnimation ? { borderRadius: 0 } : { transform: "scale(" + scaleValue + ")" }}
      >
        <div className={`video-player__preview ${!showPreview ? "video-player__preview--hidden" : ""}`}>
          {coverageImage()}
          {videoTitle && <div className="video-player__video-title">{videoTitle}</div>}
          <button
            className="video-player__play"
            onClick={handlePlayButtonClick}
            aria-label={`Play video: ${coverImage.alt}`}
          >
            <Play />
          </button>
        </div>
        <div className="video-player__video">
          <div id={videoContainerId} />
        </div>
      </div>
    </div>
  );
};

export default YoutubeVideoBlock;
