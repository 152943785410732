const initYouTube = () => {
  if (!window.YT) {
    const tag = document.createElement("script");
    tag.src = "//yt.unileversolutions.com/iframe_api";
    tag.id = "youTubeApi";
    const firstScriptTag = document.getElementsByTagName("script")[0];
    firstScriptTag.parentNode && firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
  }
};

export default initYouTube;
